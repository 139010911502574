.stacked-bar-graph .bardata {
   background:#15122e;
}
.stacked-bar-graph span {
   display: inline-block!important;
   height: 100%;
   box-sizing: border-box;
}
.stacked-bar-graph {
   width: 100%;
   height: 20px;
   color: #15122e;
}
.stacked-bar-graphs .bardata {
   background: #F7B334;
}
.stacked-bar-graphs span {
   display: inline-block!important;
   height: 100%;
   box-sizing: border-box;
}
.stacked-bar-graphs {
   width: 100%;
   height: 20px;
   color: #F7B334;
}
.css-1hsc7hf-MuiDataGrid-root .MuiDataGrid-cellContent {
    overflow: unset !important;
}