.user_experince_form_container.link_checker_settings .form_column {
  display: flex
;
  flex-direction: column !important;
  align-items: center;
}

.user_experince_form_container.link_checker_settings {
  border: 1px solid #ff601f;
  padding: 20px;
}

.row-title-text,.row-content-text{
  font-size: 13px !important;
}