.cnt_blw_wrap {
    width: 1984px;
    height: 75px;
    background: transparent linear-gradient(109deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    margin-bottom: 40px;
}
.link_cnt_hdr_blw_wrap {
    margin-left: 30px;
    margin-top: 30px;
}

.center_tab {
    text-align-last: center;
    margin-bottom: 30px;
}

button.link_rn_rpt_btn {
    /* padding: 25px;
    margin-right: 30px;
    background: #103658 !important;
    border-radius: 7px;
    color: white; */
    background: #ffffff !important;
    border-radius: 7px;
    color: #ff601f;
    margin-right: 30px;
    padding: 25px;
    border: 1px solid #ff601f !important;
}

button.link_rn_rpt_btn.active {
    z-index: 7;
    border-radius: 9px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    background-color: #ff601f !important;
    color: #fff;
}

.internal_link_checker_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2){
    display: block;
    padding-left: 80px;
}
.doughnut_intern_link .donutchart{
    height: 315px !important;
    width: 80% !important;
}
.doughnut_intern_link text{
    fill: #0b2033;
    /* font-size: 20px; */
}
.doughnut_intern_link g.donutchart-legend {
    transform: translateX(126px) translateY(154px);
}
.internal_source_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2)
{
    display: block;
    padding-left: 250px;
}
.doughnut_intern_link{
    padding: 20px 0px;
    background: transparent linear-gradient(99deg, #ffffff36 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box !important;
    opacity: 1; 
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
}
.external_link_data_row{
    display: flex;
    background: transparent linear-gradient(99deg, #ffffff36 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box !important;
    opacity: 1;
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
    margin-top: 20px;
    padding: 14px 21px 0px 0px;;
    gap: 15px;

}

.external_link_data_row .cols.backlinks {
    display: flex;
}

.external_link_data_row .cols{
    /* display: flex; */
    width: 45%;
    /* height: 65px; */
    border: 1px solid;
    /* margin-left: 22px; */
    border-radius: 5px;
    margin-bottom: 3%;
}
.external_link_data_row .cols .icon_col{
    width: 20%;
    padding: 7px 7px;
}
.external_link_data_row .cols .content_col{
    padding: 7px;
    /* font-weight: 600; */

}


.external_link_data_row .cols .content_col p{
    padding: 0px;
    margin: 0px;

}
.external_link_data_row .cols  .yellow{
    color: #F6A822;
}
.external_link_checker_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(2)
{
    display: block;
    padding-left: 35px;
}
.external_link_checker_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(3)
{
    display: block;
    padding-left: 35px;
}
button.link_rn_rpt_btn:hover {
    color: #fff !important;
}

.external_link_data_row .cols .icon_col:hover{
    background: #ff601f !important;
    color: #fff !important;
    /* border: 1px solid #ff601f !important; */
}

/* desktop view */

@media (min-width: 1200px){

    .external_link_data_row .cols .content_col{
        width: 70%;
        padding: 7px;
        font-weight: 600;
        /* font-size: 14px; */
    
    }
   
    .external_link_data_row .cols .icon_col img{
        width: 45px;
        height: 45px;
    }
}
 /* mobile view  */

 @media (max-width:500px){
    .content_col .filter_dash_col .p {
       /* font-size: 12px; */
 }
 .content_col .filter_dash_col{
    height: 70px;
 }
}

@media (min-width: 1440px) {
    .external_link_data_row .cols.backlinks {
        width: 24%;
    }
}