.cnt_blw_wrap {
    width: 1984px;
    height: 75px;
    background: transparent linear-gradient(109deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    margin-bottom: 40px;
}
.link_cnt_hdr_blw_wrap {
    margin-left: 30px;
    margin-top: 30px;
}
a {
    text-decoration: none;
}
button.link_rn_rpt_btn {
    top: 7px;
    position: relative;
    padding: 15px !important;
    margin-right: 30px;
    background: #000000 !important;
    border-radius: 7px;
    color: white;
}
button.link_rn_rpt_btn:hover{
z-index: 99;
background-color: #ff601f !important;
}

button.link_rn_rpt_btn.active {
    z-index: 7;
    background-color: #ff601f !important;
    border-radius: 9px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
}

.internal_link_checker_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2){
    display: block;
    padding-left: 80px;
}
.doughnut_intern_link .donutchart{
    height: 315px !important;
    width: 80% !important;
}
.doughnut_intern_link text{
    fill: #0b2033;
    /* font-size: 20px; */
}
.react-dropdown-select-dropdown-handle.css-1aarvou-DropdownHandleComponent.e1vudypg0 svg {
    fill: #0b2033;
}
.listdropdown_titl{
    color: #0b2033;
    /* font-size: 17px; */
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
}
.doughnut_intern_link g.donutchart-legend {
    transform: translateX(126px) translateY(154px);
}
.internal_source_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2)
{
    display: block;
    padding-left: 250px;
}
.doughnut_intern_link{
    padding: 20px 0px;
    background: transparent linear-gradient(99deg, #ffffff36 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box !important;
    opacity: 1; 
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
}
.external_link_data_row{
    display: flex;
    background: transparent linear-gradient(99deg, #ffffff36 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box !important;
    opacity: 1;
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
    margin-top: 20px;
    padding: 15px 0px;
    gap: 15px;

}
.external_link_data_row .cols{
    background: #000000;
    display: flex;
    /* width: 22%; */
    /* height: 65px; */
    border: 1px solid;
    /* margin-left: 22px; */
    border-radius: 5px;

}

@media (min-width:769px) and (max-width:1199px){
    .external_link_data_row .cols{
        display: block;
    }
}

.external_link_data_row .cols .icon_col{
    width: 20%;
    padding: 7px 7px;
}



.external_link_data_row .cols .content_col p{
    padding: 0px;
    margin: 0px;

}
.external_link_data_row .cols  .yellow{
    color: #F6A822;
}
.external_link_checker_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(2)
{
    display: block;
    padding-left: 35px;
}
.external_link_checker_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(3)
{
    display: block;
    padding-left: 35px;
}


/* tablet view */
@media(max-width:768px) {
    .listdropdown_drop{
        position: static;
    }
    .external_link_data_row .cols .content_col {
        padding: 7px;
        /* font-size: 12px; */
    }
    
}
/* mobile View  */

@media (max-width: 768px){
    .external_link_data_row .cols .icon_col img{
        width: 25px;
        height: 25px;
    }
}


  /* Media query for screens with width less than 500px */
  @media (max-width: 500px) {
    /* .external_link_data_row .cols .content_col{
        font-size: 10px;
    } */
    .external_link_data_row .cols{
        width: 100%;
    }
    /* .external_link_data_row{
        flex-direction: column;
        width: 1000px;
    } */
    .card_row {
      flex-direction: column; /* Stacks columns vertically */
      align-items: stretch; /* Ensures full width for each column */
    }
  
    .card_row .cols {
      width: 100%; /* Ensures columns take full width on smaller screens */
      margin-bottom: 20px; /* Adds space between stacked columns */
    }
  }

  input.react-dropdown-select-input.css-62bma8.e11wid6y0::placeholder {
    color: black !important;
}