img.view_per_img {
    width: 23px ;
}

.leverage_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2){
    display: block;
}
.leverage_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(3){
    display: block;
    padding-left: 45px;
}
.leverage_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(4){
    display: block;
    padding-left: 45px;
}

img.view_per_img {
    width: 23px ;
}
.itemdata{
    margin-bottom: 7px;
    word-wrap: break-word;
    /* display: inline-block; */
    width: 100%;
    /* font-size: 14px; */
    letter-spacing: 0;
    line-height: 28px;
    display: list-item;
    color:#103658 !important;
    margin-left: 215px;
    list-style-type: square; 
    list-style-position: inside;
}
p.MuiTypography-root.MuiTypography-body1.error_result_data.css-ahj2mt-MuiTypography-root {
    display: inline;
}
button.link_rn_rpt_btn.performance_data {
    margin-left: 411px;
}
.item_bx_wrap_days{
    /* font-size: 14px; */
    color: #ffffff;
    text-transform: capitalize;
    background: #ffffff40;
    border: 0px;
    padding: 11px 15px;
    border-radius: 9px;
    text-align: center;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    background: transparent linear-gradient(8deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
    color: white;
}
p.MuiTypography-root.MuiTypography-body1.css-1pnmrwp-MuiTypography-root {
    color: white;
}
.performance_accordion_div p.MuiTypography-root.MuiTypography-body1.css-1pnmrwp-MuiTypography-root,
.performance_accordion_div .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    color: rgb(0, 0, 0);
}
.performance_accordion_div .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
background: white linear-gradient(8deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
margin-bottom: 3px;
}
.performance_accordion_div p.MuiTypography-root.MuiTypography-body1:nth-of-type(1){
    width: 30%;
}
.performance_accordion_div p.MuiTypography-root.MuiTypography-body1:nth-of-type(2){
    width: 60%;
}
@media(max-width: 500px){
    .performance_accordion_div p.MuiTypography-root.MuiTypography-body1:nth-of-type(1) {
        width: 50%;
    }
    .itemdata{
        margin-left: 0px;
    }
    .performance_accordion_div p.MuiTypography-root.MuiTypography-body1:nth-of-type(2){
        width: 100%;
    }
}