/* button.site_rn_rpt_btn {
    padding: 12px;
    margin-right: 30px;
    background: transparent linear-gradient(147deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    color: white;
} */
button.site_rn_rpt_btn {
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */

    background: #ffffff !important;
    border: 1px solid #ff601f !important;
    border-radius: 9px;
    color: #ff601f;
    font-family: "Montserrat Alternates", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    /* font-size: 15px; */
    /* color: white; */
    /* border: 1px; */
    position: relative;
    /* z-index: -1; */
    padding: 12px 12px 15px 12px;
    margin-right: 30px;
    /* background: #103658 !important; */
    border-radius: 9px;
    overflow: hidden;
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -o-transition: transform 0.5s;
}

.site_cnt_hdr_blw_wrap {
    margin-left: 30px;
    margin-top: 30px;
    margin-bottom: -8px;
}

.site_cnt_hdr_blw_wrap button {
    cursor: pointer;
}

.MuiBox-root.css-164r41r {
    color: #0b2033;
}

.site_blw_wrap {
    width: 100%;
    background: #ffffff !important;
    opacity: 1;
    position: relative;
    padding: 3px;
}

button.site_rn_rpt_btn.active {
    z-index: 7;
    border-radius: 9px;
    opacity: 1;
    background: #ff601f !important;
}

.MuiTabPanel-root {
    padding: 0px !important;
}

.MuiBox-root.css-1gsv261 {
    /* margin-left: 60px; */
    margin-top: 25px;
    margin-bottom: -8px;
}

@media (max-width: 500px) {
    .MuiBox-root.css-1gsv261 {
        flex-direction: row;
        /* Stack buttons vertically */
    }
}

.MuiTabs-flexContainer.MuiTabs-flexContainer button {
    /* color: #ff601f  !important; */
    /* background: #ffffff  !important; */
    margin-right: 15px;
    border-radius: 9px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    border: 1px solid #ff601f !important
}

span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    display: none;
}

p.button_score_org {
    display: inline-block;
    color: #0b2033;
    /* width: 59px; */
    /* height: 35px; */
    background: #e6e7eb linear-gradient(139deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    /* justify-content: center; */
    padding-left: 19px;
    padding-top: 9px;
    padding-right: 19px;
    padding-bottom: 9px;

}

.site_chart_info {
    width: 100%;
    background: transparent linear-gradient(142deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    margin-top: 50px;
    padding-top: 12px !important;
    padding-bottom: 30px !important;
}

.site_Chart_info_inner {
    width: 100% !important;
    margin: 0 auto !important;
    padding-bottom: 50px;
    color: #0b2033;
    /* font-size: 10px; */
}

.site_chart_info p {
    display: flex;
    align-content: center;
    justify-content: center;
    color: white;
}

.data_table_page {
    margin-top: 30px;
    width: "calc(100% - 40px)";
    margin-left: "auto";
    margin-right: "auto";
}

button {
    border: 0px !important;
}

.cnt_hdr_blw_wrap.site_content_wrap_butn button {
    border: 0px;
    width: 100px;
    margin-left: 20px;
    margin-bottom: 10px;
    height: 60px;
    background: #ffffff 0% 0% no-repeat;
    border-radius: 9px;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0b2033;
}

.cnt_hdr_blw_wrap.site_content_wrap_butn {
    padding: 25px 0px 0px 24px;
    display: flex;
}

.onpage_button_active,
.cnt_hdr_blw_wrap.site_content_wrap_butn button:hover {
    background-color: #0b2033 !important;
    color: white !important;
}

.cnt_hdr_top_wrap.image-details {
    margin-bottom: 40px;
}

.pagehealth_missing_waring_wrap.plagiarisam_chart_outer {
    width: 220px;
    height: 167px;
    /* background: transparent linear-gradient(158deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box; */
    border-radius: 9px;
    opacity: 1;
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
    margin-right: 45px;
    padding: 15px 21px;
    text-align: center;
    letter-spacing: 0px;
    /* font-size: 19px; */
    background-color: #103658;
}

.siteaudit_missing_waring_wrap.siteaudit_chart_outer {
    width: 220px;
    height: 167px;
    background: #ff601f  linear-gradient(158deg, #ffffff00 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    /* -webkit-backdrop-filter: blur(11px); */
    /* backdrop-filter: blur(11px); */
    margin-right: 45px;
    padding: 25px 20px;
    text-align: center;
    letter-spacing: 0px;
    /* font-size: 19px; */
    /* margin:0px auto; */
    display: inline-block;
}

.site_Chart_info_inner p {
    color: #ffffff;
    text-align: center;
    /* font-size: 17px; */
    font-weight: 700;
}

p.sitedataheader {
    color: #0b2033;
    /* font-size: 23px; */
    padding-left: 25px;
}

p.dummysiteheader {
    position: relative;
    /* bottom: 196px; */
    margin-left: 58px;
}

.domain_link_page {
    display: flex;
    justify-content: space-between;
}

.google_analytics_submit {
    background: #ff601f;
    color: #ffffff;
    border-radius: 5px;
    border: 1px solid #ff601f !important;
}

.google_analytics_submit:hover {
    /* background: #ffffff; */
    color: #000;
    /* border: 1px solid #ff601f !important; */
}

@media(max-width:767px) {

    .domain_link_page {
        display: block;
    }
}

@media(max-width:768px) {
    .site_Chart_info_inner p {
        color: #0b2033;
        text-align: center;
        /* font-size: 15px; */
        font-weight: 700;
    }

    .siteaudit_chart_outer_container {
        display: flex;
        flex-wrap: wrap;
        /* Allow items to wrap onto multiple lines if needed */
        justify-content: space-around;
        /* Distribute space evenly between items */
        align-items: center;
        /* Align items vertically in the center */
        gap: 20px;
        /* Add space between items */
    }

    .siteaudit_missing_waring_wrap {
        /* flex: 1 1 200px; Allow items to grow and shrink, with a base width of 200px */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .siteaudit_chart {
        margin: 0 auto;
        /* Center the chart horizontally */
    }

}

@media(max-width:500px) {
    .siteaudit_missing_waring_wrap.siteaudit_chart_outer {
        width: 100px;
    }

    .site_Chart_info_inner p {
        color: #0b2033;
        text-align: center;
        /* font-size: 12px; */
        font-weight: 700;
    }

    .site_Chart_info_inner {
        width: 250px !important;
        /* margin: 3px 10px !important; */
        /* padding-bottom: 23px; */
        color: #0b2033;
        /* font-size: 10px; */
    }

    .siteaudit_chart_outer_container {
        display: flex;
        flex-wrap: wrap;
        /* Allow items to wrap onto multiple lines if needed */
        justify-content: space-around;
        /* Distribute space evenly between items */
        align-items: center;
        /* Align items vertically in the center */
        gap: 20px;
        /* Add space between items */
    }

    .siteaudit_missing_waring_wrap {
        flex: 1 1 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .siteaudit_chart {
        margin: 0 auto;
        /* Center the chart horizontally */
    }

}

/* General Flexbox Style for Button Group */
.site_table_blw_wrap .button-group {
    display: flex;
    flex-wrap: wrap;
    /* Allows wrapping to the next line if needed */
    justify-content: space-between;
    /* Adds space between buttons */
}

/* Media Query for Small Screens */
@media (max-width: 500px) {
    .button-group {
        flex-direction: column;
        /* Stack buttons in a column */
        align-items: center;
        /* Center align buttons */
    }

    .button-group th {
        width: 100%;
        /* Make each button full width */
        display: flex;
        justify-content: center;
        /* Center content in each column */
        margin-bottom: 10px;
        /* Add some margin between buttons */
    }

    .on_page_table {
        width: 100%;
    }
}


