.userdata {
    text-align: center;
    float: left;
    width: 100%;
    padding-bottom: 3em;
}

.userdata h1 {
    padding: 16px 0px;
    text-align: center;
    margin: 0px;
    /* font-size: 36px; */
    /* letter-spacing: 1px; */
    color: #000;
    opacity: 1;
}

.userdata_inner h1 {
    font-size: 24px !important;
    font-weight: 1000 !important;
    letter-spacing: 1px !important;
    font-family: 'Space Grotesk';
}

.userdata_inner p {
    font-size: 1.2rem;
    color: #0b2033;
    /* font-weight: 800; */
    line-height: 1.43;
    font-family: 'Space Grotesk';

}

.userdata_inner {
    background: #ffffff 0% 0% no-repeat padding-box;
    margin: 0 auto;
    text-align: center;
    /* max-width: 90%; */
    border-radius: 17px;
    padding: 1em 0em 2em;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
}


.userdata_innersas {
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 17px;
    margin: 0 auto;
    max-width: 650px;
    padding: 1em 0 2em;
    text-align: center;
}

.userdata_inner table {
    width: 85%;
    /* margin: 0 auto; */
}

.user_country select:focus-visible {
    outline: unset;
}

.user_country input:focus-visible {
    outline: unset;
}

.user_country select {
    /* font-size: 17px; */
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #ff601f;
    width: 60%;
    /* padding: 17px 22px; */
    padding: 10px 0px;

}

/* .keywordform_outer .frm_container input[type="text"]{
width: 50% !important;
} */
.user_country input {
    /* font-size: 17px; */
    background: #E9EFF6;
    border-radius: 5px;
    border: 1px solid #ff601f;
    /* width: 60%; */
    /* padding: 17px 22px; */
    padding: 10px 5px;
    color: #1a1818;
    width: 72%;
    margin-left: auto;
    margin-right: auto;
}

.form-group.user_country {
    /* margin-bottom: 2em; */
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

span.error-value {
    display: block;
}

.user_country label {
    /* width: 13%; */
    display: inline-block;
    text-align: left;
    /* font-size: 18px; */
    color: #0b2033;
}

.websitetype_inner label {
    width: 13%;
    display: inline-block;
    text-align: left;
    /* font-size: 18px; */
    color: #0b2033;
}

.form_next button {
    border-radius: 10px;
    font-size: 16px;
    position: relative;
    color: #fff;
    width: 20%;
    padding: 20px 20px;
    background: #ff601f;
    margin-left: 20px;
    font-weight: 500;
}

#displayText {
    display: none;
}

.websitename label {
    /* width: 19%; */
}

label.webtype {
    width: 30%;
    display: inline-block;
    /* font-size: 18px; */
    color: #0b2033;
}

.form-group.websitetype {
    margin-bottom: 2em;
    float: left;
}

.websitetype_inner {
    display: inline-block;
    width: 35%;
}

label.websiteauthentype {
    width: 60%;
    display: inline-block;
    /* font-size: 18px; */
    color: #232020;
    text-align: left;
}

.form-group.websiteauthen {
    float: left;
    width: 100%;
    margin-bottom: 2em;
}

.websiteauthen_inner {
    display: inline-block;
    width: 35%;
}

.websiteauthen_inner label {
    text-align: left;
    /* font-size: 18px; */
    color: #0b2033;
}

/* .userdata input[type=text]:focus {
    outline: 0px solid #ff5300;
    box-shadow: 0px 0px 7px 1px #ed4d00;
} */

.form-group.domain_owner {
    /* float: left;
    width: 100%; */
    /* margin-bottom: 2em; */
    display: flex;
    flex-direction: row;


}

.form-group.domain_owner label {
    /* font-size: 17px; */
    /* color: #0b0b0b; */
    /* width: 100%; */
    /* float: left; */
    /* padding-left: 30px; */
}

.form-group.domain_owner.domain_ownertext input:focus-visible {
    outline: unset;
}

.form-group.domain_owner.domain_ownertext input {
    /* font-size: 17px; */
    /* background: #E9EFF6; */
    border-radius: 5px;
    border: 1px solid #ffffff;
    /* width: 60%; */
    padding: 10px 17px;
}


.form-group.domain_owner.domain_ownertext label {
    margin-bottom: 1em;
}

button.skip {
    /* background: #28216d; */
    transition: 0.5s;
}

.authen_approve {
    /* display: none; */
    float: left;
    width: 100%;
    margin-bottom: 2em;
}

.authen_approve>div label {
    width: 13%;
    display: inline-block;
    text-align: left;
    /* font-size: 18px; */
    color: #090909;
}

.authen_approve input {
    /* font-size: 17px; */
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #111112;
    width: 60%;
    padding: 17px 22px;
    color: #0e0d0d;
}

.authen_approve>div {
    margin-bottom: 1em;
}

.authen_approve input:focus-visible {
    outline: unset;
}

button.nextbutton:hover {
    background: #ff601f;
    color: #ffffff;
    /* box-shadow: 0px 0px 7px 5px #ff601fd6; */
}

button.nextbutton {
    transition: 0.5s;
}

button.skip:hover {
    background: #ff601f;
}

.error-value {
    color: #ff0000;
    font-size: smaller;
}

.react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
    border: 1px solid #0b2033;
    border-radius: 4px;
    width: 300px;
}

#alert-dialog-slide-description .react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
    border: 1px solid #fff;
}

.form-inner-data {
    flex-basis: 70% !important;
}

.react-dropdown-select-content.react-dropdown-select-type-single.css-jznujr-ContentComponent.e1gn6jc30 {
    display: flex;
    /* align-content: center; */
    align-items: center;
}

.form-group.user_country.websitename {
    /* float: left;
    width: 100%; */
    /* margin-top: 1em; */
    display: flex !important;
    flex-direction: column;

}

.copybutton {
    /* margin: 2%; */
    padding: 12px 10px;
    /* font-size: 20px; */
    position: relative;
    /* // left: 30%; */
    /* margin-top: 10px; */
    cursor: pointer;
    color: white;
    background: #ff601f;
    border-radius: 10px;
    width: 15%;
    display: flex;
    /* Enables flexbox */
    align-items: center;
    /* Vertically centers the text and icon */
    justify-content: center;
    /* Horizontally centers the content */
    font-weight: bold;
}

.userdata.addrs .user_country input {
    background: #ffffff;
}

.userdata.addrs .react-dropdown-select-content.react-dropdown-select-type-single.css-jznujr-ContentComponent.e1gn6jc30 {
    justify-content: center;
}

.userdata.addrs form.frm_container {
    justify-content: center;
    display: grid;
    margin: 0 auto;
}

.user_country_data input {
    /* font-size: 17px; */

    width: 23%;
    /* padding: 17px 22px; */
    padding: 10px 22px;
    color: #140101;
}

.form_next.div_next_button {
    margin-top: 15px;
}

.domain-errordata {
    color: rgb(255, 5, 5);
    display: flex;
    flex-direction: column;
}

.user_country_data {
    margin-bottom: 20px;
}

/* .form-group label{
    font-size: 20px;
    font-weight: 700;
} */

@media (max-width: 768px) {
    .userdata.addrs form.frm_container {
        width: 35% !important;
    }

    .form_next button {
        border-radius: 10px;
        /* font-size: 15px; */
        position: relative;
        color: #fff;
        width: 70%;
        /* margin-left: 20px; */
        padding: 20px 20px;
        background: #ff601f;
        margin-bottom: 20px;

    }

    /* .html-js-radio{
        margin-left:80px
     } */
}

@media(max-width: 500px) {
    .user_country_data input {
        padding: 0px !important;
    }

    .form-group.user_country.websitename {
        float: left;
        /* width: 60%; */
        margin-top: 1em;
    }

    .authen_approve {

        width: 80%;
    }

    .form-group label {
        /* font-size: 12px; */

    }

    .form-group.domain_owner label {
        width: 80%;
        /* font-size: 14px; */
    }

    .websitetype_inner label {
        width: 13%;
        display: inline-block;
        text-align: left;
        /* font-size: 10px; */
        color: #0b2033;
    }

    .websiteauthen_inner label {
        /* font-size: 12px; */
    }

    .form_next button {
        /* font-size: 12px; */
    }
}

@media(min-width:1200px) {
    .userdata_inner table th label {
        width: 200px
    }

    tr.form-group.user_country.websitename {
        width: 120%;
    }

    /* .html-js-radio{
        margin-left:300px
     } */
}



.selectDomain {
    width: 100% !important;
}

.selectDom {
    width: 100% !important;
}



.domainownership {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #ff601f;
    text-align: center;
    font-size: 13px;
    max-width: 500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
}


ul.MuiList-root.MuiList-padding.css-1xcnmzm {
    list-style: none;
    margin: 0px 0px 0px !important;
    position: relative;
    padding: 0px;
    display: grid;
    -webkit-box-pack: center;
    justify-content: center;
}


.form-group.domain_owner.domain_ownertext.domain_input_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}


.form-group.domain_owner.domain_ownertext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.websitenamess label {
    display: flex;
    flex-direction: column;
    margin-left: 6% !important;
    margin-top: 5%;
    margin-bottom: 2%;
}

/* @media (max-width: 500px) {
    .user_country input {
        background: #e9eff6 !important;
        border: 1px solid #e9eff6 !important;
        border-radius: 5px !important;
        color: #1a1818 !important;
        padding: 17px 22px !important;
        width: 73% !important;
    }
}

@media (max-width: 654px) {
    .form-group.domain_owner.domain_ownertext input {
        margin-top: 6px !important;
    }
} */


/* .userdataselect {
    width: 375px !important;
} */


.userdataselectdomain::placeholder {
    text-align: left;
    /* Align the placeholder text to the left */
}



.userdataselect__control,
.selectDomain__control,
.selectDom__control {
    border-color: #ff601f !important;
    box-shadow: none !important;
    /* Custom border color */
}


.userdataselect__dropdown-indicator,
.userdataselect__dropdown-indicator:hover,
.selectDomain__dropdown-indicator,
.selectDomain__dropdown-indicator:hover,
.selectDom__dropdown-indicator,
.selectDom__dropdown-indicator:hover {
    color: #ff601f !important;
}

.userdataselect__input {
    text-align: left;
    display: flex;
    width: 100% !important;
}


.userdataselect__menu {
    background-color: #f0f0f0;
    /* Custom background for the dropdown */
}

.userdataselect__placeholder {
    font-weight: normal;
    text-align: start;
}

.userdataselect__single-value {
    font-weight: normal;
    text-align: left;
}


@media(min-width:320px) {

    .userdataselect {
        width: 220px !important;
    }

    .userdataselectdomain {
        width: 183px !important;
    }

}

@media(min-width:375px) {

    .userdataselectdomain {
        width: 183px !important;
    }

}

@media(min-width:425px) {

    .userdataselect {
        width: 262px !important;
    }

    .userdataselectdomain {
        width: 220px !important;
    }

}

@media(min-width:768px) {

    .userdataselect {
        width: 377px !important;
    }

    .userdataselectdomain {
        width: 435px !important;
    }

}

@media(min-width:1024px) {

    .userdataselect {
        width: 425px !important;
    }

    .userdataselectdomain {
        width: 425px !important;
    }

}


@media(max-width:425px) {
    .react-dropdown-select-content span {
        color: #0b2033 !important;
        margin-top: 0px !important;
    }
}


.react-dropdown-select.userdataselect.css-16vukcu.e1gzf2xs0 {
    padding: 2px 5px !important;
    border-radius: 5px !important;
}


.react-dropdown-select-content span {
    color: #0b2033 !important;
    margin-top: 7px !important;
}


.password-container {
    position: relative;
    width: 100%;
}

input[type="password"],
input[type="text"] {
    width: 90%;
    padding-right: 35px;
    background-color: #fff;
    /* Extra space for the button */
}

input[type="password"]::placeholder,
input[type="text"]::placeholder {
    text-align: left; /* Ensures the placeholder is left-aligned */
}

.togglepwd {
    position: absolute;
    right: 10px;
    /* Adjust for right side of the input field */
    top: 50%;
    transform: translateY(-50%);
    /* Center the button vertically */
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 2;
    /* Ensure the button is on top of the input */
}