@media screen and (min-width: 983px) {
    .scan-results .scan-result__content {
        padding: 24px;
    }
}
.scan-results .scan-result__content {
    background-color: #fff;
    border-radius: 12px;
    border: solid 1px #e3e5e8;
}

.scan-results .scan-result__content>.scan-title {
    --accessscan-title-font-weight: 600;
    display: block;
    margin-block-end: 12px;
}

@media screen and (min-width: 737px) {
    .scan-results .scan-result__content>.scan-title {
        --accessscan-title-font-size: 16px;
    }
}
.scan-wrapper .scan-title, .scan-wrapper p {
    margin-block: 0;
}
.scan-title {
    --accessscan-title-color: var(--accessscan-site-title-color);
    color: var(--accessscan-title-color);
    font-size: var(--accessscan-title-font-size) !important;
    font-weight: var(--accessscan-title-font-weight) !important;
    line-height: var(--accessscan-title-line-height) !important;
}

@media screen and (min-width: 983px) {
    /* .scan-results .scan-result__content>.scan-text {
        font-size: 16px;
    } */
}
.scan-results .scan-result__content>.scan-text {
    color: #3a4b61;
    font-weight: 400;
    line-height: 1.25;
    margin-block-end: 32px;
}


.scan-results .scan-result__content .scan-code-example {
    margin-block-end: 16px;
}
@media screen and (min-width: 737px) {
    /* .scan-results .scan-result__content .scan-cta__small {
        font-size: 16px;
    } */
}
.scan-results .scan-result__content .scan-cta__small {
    line-height: 1.5;
}
.scan-wrapper p {
    margin-block: 0;
}

@media screen and (min-width: 983px) {
    .scan-results .scan-result__line.js-ace-active {
        --accessscan-result-expanded-padding: 24px;
    }
}
.scan-results .scan-result__content .scan-code-example .scan-list {
    display: flex;
    flex-direction: column;
    margin-block-end: 12px;
    padding-block: 0;
    padding-inline: 40px 0;
    row-gap: 12px;
}
.scan-title {
    --accessscan-title-color: var(--accessscan-site-title-color);
    color: var(--accessscan-title-color);
    font-size: var(--accessscan-title-font-size) !important;
    font-weight: var(--accessscan-title-font-weight) !important;
    line-height: var(--accessscan-title-line-height) !important;
}
.scan-results .scan-result__content .scan-code-example .scan-list .scan-list__item {
    background-color: #ff601f;
    border-end-end-radius: 8px;
    border-start-end-radius: 8px;
    color: #fff;
    counter-increment: list-item;
    padding: 4px;
    position: relative;
}
.scan-results .scan-result__line.js-ace-active {
    --accessscan-result-expanded-border: solid 1px rgba(227, 229, 232, 1);
    --accessscan-result-expanded-maxheight: unset;
    --accessscan-result-expanded-opacity: 1;
    --accessscan-result-expanded-visibility: visible;
    --state-transform: rotate(180deg);
}
.scan-results .scan-result__content .scan-code-example .scan-list .scan-list__item:before {
    align-items: start;
    background-color: #103658;
    border-end-start-radius: 8px;
    border-start-start-radius: 8px;
    box-sizing: border-box;
    content: counter(list-item);
    display: flex;
    /* font-size: 14px; */
    font-weight: 700;
    height: 100%;
    inset-block: 0;
    inset-inline-start: -40px;
    justify-content: center;
    padding: 12px;
    position: absolute;
    width: 40px;
}
.preclass {
    display: block;
    font-family: monospace;
    unicode-bidi: isolate;
    white-space: pre;
    margin: 1em 0px;
}

@media screen and (min-width: 737px) and (max-width: 982px) {
    .scan-results .scan-result__line.js-ace-active {
        --accessscan-result-expanded-padding: 16px;
    }
}
.scan-results .scan-result__expanded {
    background-color: #f7fafd;
    border-block-end: var(--accessscan-result-expanded-border);
    column-gap: 24px;
    max-height: var(--accessscan-result-expanded-maxheight);
    opacity: var(--accessscan-result-expanded-opacity);
    padding: var(--accessscan-result-expanded-padding);
    visibility: var(--accessscan-result-expanded-visibility);
    width: 100%;
}
.scan-results .scan-result__line {
    --accessscan-result-background: transparent;
    --accessscan-result-expanded-border: unset;
    --accessscan-result-expanded-maxheight: 0;
    --accessscan-result-expanded-opacity: 0;
    --accessscan-result-expanded-padding: 0;
    --accessscan-result-expanded-visibility: hidden;
    --state-transform: rotate(0deg);
    display: flex;
    flex-wrap: wrap;
}
@media screen and (min-width: 983px) {
    .scan-results .scan-result__info {
        padding: 24px;
    }
}
.scan-results .scan-result__expanded {
    background-color: #f7fafd;
    border-block-end: var(--accessscan-result-expanded-border);
    column-gap: 24px;
    max-height: var(--accessscan-result-expanded-maxheight);
    opacity: var(--accessscan-result-expanded-opacity);
    padding: var(--accessscan-result-expanded-padding);
    visibility: var(--accessscan-result-expanded-visibility);
    width: 100%;
}
.scan-results .scan-result__line.js-ace-active {
    --accessscan-result-expanded-border: solid 1px rgba(227, 229, 232, 1);
    --accessscan-result-expanded-maxheight: unset;
    --accessscan-result-expanded-opacity: 1;
    --accessscan-result-expanded-visibility: visible;
    --state-transform: rotate(180deg);
}
.scan-results .scan-result__line {
    --accessscan-result-background: transparent;
    --accessscan-result-expanded-border: unset;
    --accessscan-result-expanded-maxheight: 0;
    --accessscan-result-expanded-opacity: 0;
    --accessscan-result-expanded-padding: 0;
    --accessscan-result-expanded-visibility: hidden;
    --state-transform: rotate(0deg);
    display: flex;
    flex-wrap: wrap;
}

.scan-code pre {
    white-space: break-spaces;
}
.scan-results__section {
    display: flex;
}
.scan-result__expanded{
    flex-basis: 70%;
}
.scan-result__info{
    flex-basis: 30%;
}
.scan-result__info {
    background-color: #f7fafd;
    /* border-block-end: var(--accessscan-result-expanded-border); */
    column-gap: 24px;
    max-height: var(--accessscan-result-expanded-maxheight);
    opacity: var(--accessscan-result-expanded-opacity);
    padding: var(--accessscan-result-expanded-padding);
    visibility: var(--accessscan-result-expanded-visibility);
    /* width: 100%; */
    /* border: #734f1a; */
}
.scan-result__info {
    background-color: #fff;
    border-radius: 12px;
    border: solid 1px #e3e5e8;
    margin-left: 19px;
    height: fit-content;
   
}
 .scan-result__header{
    align-items: center;
    border-block-end: solid 1px #e3e5e8;
    display: flex;
    justify-content: space-between;
    padding-block: 2px;
    padding-inline: 16px;
    background-color: #ffffff;
    border-radius: 10px;
}

@media (min-width:1024px) and (max-width: 1439px) {
    .scan-results .scan-result__expanded {
        width: 60%;
    }
}
.accessbility .performance_accordion_div p.MuiTypography-root.MuiTypography-body1:nth-of-type(2){
    width: 100% !important;
   
}
.dummyimg{
    text-align: end;
    margin-right: 6em !important;
}

  
  /* .zoom:hover { */
   /* transform: scale(3.5)!important;  (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  /* } */
  .dummydivdata .css-o4b71y-MuiAccordionSummary-content{
    align-items: center;
  }
  h5.scan-title{
    margin: 0px;
  }
  .accessbility_report_score_table .css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child
{
    position: unset!important;
}

.accessbility_report_score_table .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner div .MuiDataGrid-columnHeader:nth-child(1),
.accessbility_report_score_table .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner div .MuiDataGrid-columnHeader:nth-child(2),
.accessbility_report_score_table .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner div .MuiDataGrid-columnHeader:nth-child(3){
    position: relative  !important;
    z-index: 9999999  !important;
    background-color: #ff601f !important; 

}