.css-1hsc7hf-MuiDataGrid-root .MuiDataGrid-cellContent {
   overflow-wrap: anywhere !important;
}
.ignore_button_active {
   /* width: 60px; */
   /* height: 33px; */
   padding: 12px;
   background-color: #103658;
   color: white;
   border-radius: 5px;
}
.ignore_button_active:hover{
   background: #fff; 
   color: #fe874e;
   box-shadow: 0px 0px 2px 2px #fe874e;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root {
   display: none;
} */

.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2)
{
   display: block;
   padding-left: 20px;
}
.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(5)
{
   display: block;
   padding-left: 70px;
 
}

.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(3){
display: flex !important;
}
.spell_checker_detail_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(4){
   display: flex !important;
}