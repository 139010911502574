.userdatas {
    text-align: center;
    float: left;
    width: 100%;
    padding-bottom: 3em;
}
.userdatas h1 {
    padding: 30px 0px;
    text-align: center;
    margin: 0px;
    /* font-size: 36px; */
    letter-spacing: 1px;
    color: #FFFFFF;
    opacity: 1;
}
.userdatas_inner p {
    /* font-size: 25px; */
    color: #fff;
    font-weight: 700;
}
.userdatas_inner {
    /* background: #fe874e 0% 0% no-repeat padding-box; */
    margin: 0 auto;
    text-align: center;
    max-width: 66%;
    border-radius: 17px;
    padding: 1em 0em 2em;
}

