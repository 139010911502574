/* keywordAddForm */

.keyword-select-container {
  margin: auto;
  width: 100% !important;
  margin-left: inherit;
}

/* Add custom styles with the prefix */
.addkeyword__control {
  border-color: #ff601f;
  /* Custom border color */
  padding: 8px;
  height: 35px;
  align-content: center;
}

.addkeyword__input {
  text-align: left;
  display: flex;
  width: 100% !important;
}


.addkeyword__menu {
  background-color: #f0f0f0;
  /* Custom background for the dropdown */
}

.addkeyword__placeholder {
  font-weight: normal;
  text-align: start;
}

.addkeyword__single-value {
  font-weight: normal;
}

.keyword-dropdown-group {
  display: flex;
  align-items: center
}

.addkeyword__single-value {
  text-align: start;
}

.keyword-unselect {
  color: gray;
  /* Placeholder color */
}

.keyword-select {
  color: black;
  /* Default color for options */
}

.form-group.user_country.websitename.keyword_page {
  margin-top: 1em;
  display: block !important;
}

.keyword {
  height: 35px;
  margin-bottom: 1em;
  border-radius: 5px;
  width: 84% !important;
  padding: 2px 0px 2px 20px;
}

.addbrand_model {
  width: auto !important;
  background-color: #fff;
  padding: 8px;
  border: 1px solid#ff601f;
  border-radius: 5px;
  margin-right: 20px;
}

/* @media (max-width: 700px) {
  .keyword_label {
    text-align: center;
  }
}

@media (min-width: 700px) {
  .keyword_label {
    text-align: justify;
  }
}

@media (min-width: 250px) and (max-width: 2600px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 100% !important;
  }
}

@media (min-width: 761px) and (max-width: 768px) {
  .keyword {
    width: 100% !important;
    padding-left: 4%;
  }
}

@media (min-width: 1000px) and (max-width: 1399px) {
  .keyword {
    width: 100% !important;
    padding-left: 4%;
  }
}

@media (min-width: 1400px) and (max-width: 1700px) {
  .keyword {
    width: 100% !important;
  }
}

@media (min-width: 1700px) and (max-width: 2000px) {
  .keyword {
    width: 100% !important;
  }
}

@media (min-width: 2000px) and (max-width: 2500px) {
  .keyword {
    width: 100% !important;
  }
}

@media (min-width: 2500px) {
  .keyword {
    width: 100% !important;
  }
}

.form-group.user_country {
  margin-bottom: 2em;
}

@media(max-width:500px) {

  .keyword-dropdown-group {
    display: block;
  }

  .custom-select-container {
    width: 100%;
    margin: auto;
  }

}

@media (max-width: 780px) and (min-width: 501px) {

  .keyword-dropdown-group {
    display: flex;
    margin-left: auto;
    text-align-last: right;
  }

  .custom-select-container {
    width: 100px;
  }

} */


.card {
  /* width: 400px; */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 2px 2px 8px #ff601f;
  overflow: hidden;
  margin: 16px;
  transition: transform 0.2s;
  padding: 5%;
}


.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  height: 230px !important;
  position: absolute !important;
  left: auto !important;
  top: 50px !important;
  right: 5px !important;
}


.myClass {
  width: 100% !important;
}

.campaigns-addedit-row {
  display: flex;
  margin-bottom: 10px;
}

.keyword-col1-fields {
  width: 100%;
  margin-bottom: 12px;
}

.keyword-col2-fields {
  width: 100%;
  margin: 0 0px 0px 25px;
}

@media (max-width: 900px) {
  .campaigns-addedit-row {
    display: block;
  }
  
  .keyword-col2-fields {
    width: 100%;
    margin: 0px;
  }

}

.keyword-dialog {
  /* width: 550px !important; */
  /* height: 460px !important; */
  max-width: none;
  max-height: none;
  border-radius: 10px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: auto !important;
}

.keyword-cancel {
  background: #ffffff;
  padding: 12px 25px;
  border-radius: 10px;
  color: #ff601f;
  border: 1px solid #ff601f !important;
}

.keyword-submit {
  background: #ff601f;
  padding: 12px 25px;
  border-radius: 10px;
  color: #ffffff;
  border: 1px solid #ff601f !important;
  margin-left: 35px;
  margin-top: 20px;
}

.keyword-dialog-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.campaign-name {
  height: 37px;
  border-radius: 4px;
  padding: 0 0 0 17px !important;
}