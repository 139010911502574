.extract code {
   color: #eb1717;
   /* font-size: 18px; */
}
 .cnt_hdr_content_table li.info {
    font-weight: 600;
     /* font-size: 14px; */
     letter-spacing: 0.9px;
    }
    .cnt_hdr_content_table {
     background: #f0f8ff14 !important;
   }


   .code_audit_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2)
   {
      display: block;
      padding-left: 85px;

   }
   .cnt_hdr_content_table .box_wrap {
      word-wrap: break-word;
  }
  .post__content #results>ol>li.w3_error strong, .h_errors {
color:#ff0202;
font-weight: 800;
}
.post__content #results>ol>li.w3_warning strong,.h_warn{
   color:#ff5300;
   font-weight: 800;
}
.h_ferr{
   font-weight: 800;
}
.fatal_errore{
   background-color: #fe4e58  !important;
   color: #000000
}
.err_inner{
   background-color: #f30000  !important;
   color: #000000 
}
.warning_erre{
   background-color: #fdfe4e  !important;
   color: #000000 
}