.site_blw_wrap.regressiontest{
    margin-top: 35px;
}
/* td.table_td_regression {
    padding-left: 140px;
    color: white;
    font-size: 14px;
} */
.site_regress_chart_info{
    width: 100%;
background: transparent linear-gradient(142deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
opacity: 1;
backdrop-filter: blur(11px);
-webkit-backdrop-filter: blur(11px);
margin-top: 20px;
padding-top: 12px !important;
padding-bottom: -11px !important;

}
.MuiTab-textColorPrimary.Mui-selected {
    z-index: 7;
    background: #ff601f !important;
}
.MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button:hover{
    z-index: 7; 
    background: #ff601f !important;
}
.tabbutton{
    width: 130px;
height: 50px;
/* UI Properties */
background: transparent linear-gradient(142deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
border-radius: 9px;
opacity: 1;
backdrop-filter: blur(11px);
margin-left: 0px;
}
.competitor_test_filter .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    height: 100%;
}
