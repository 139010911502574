.plagiarisam_missing_waring_wrap{
    /* Layout Properties */
    color: #0b2033;
/* width: 153px; */
height: 50px;
background: #fe874e;
border-radius: 9px;
opacity: 1;
backdrop-filter: blur(11px);
-webkit-backdrop-filter: blur(11px);
margin-right: 21px;
padding: 10px 21px;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}
.plagiarisam_missing_waring_wrap p {
    margin: 0px;
}
.plagiarisam_missing_waring_wrap.plagiarisam_chart_outer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
button.errorbuttonView {
    color: white;
    background: #f30000;
    border-radius: 8px;
    width: 150px;
    padding: 10px;
}
.box_background_wrap {
    
    height: 50px;
    background: transparent linear-gradient(99deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(11px);
    margin-top: 35px;
    margin-bottom: 30px;
    display: flex;
    -webkit-backdrop-filter: blur(11px);
    align-items: center;
    justify-content: center;
}
.plagiarisam_scan_missing_waring_wrap{
width: 220px;
height: 120px;
background: #fff;
border-radius: 9px;
opacity: 1;
backdrop-filter: blur(11px);
margin-right: 45px;
padding: 0px 21px;
text-align: center;
letter-spacing: 0px;
/* font-size: 19px; */
}
.plagiarisam_scan_missing_waring_wrap p:first-child {
    color: #ff601f;
    margin: 10px 0px 0px 0px;
    /* font-size: 30px; */

}
.plagiarisam_scan_missing_waring_wrap p {
    margin: 0px;
    /* font-size: 20px; */
}
.plagiarisam_scan_missing_waring_wrap.plagiarisam_chart_data {
    display: flex;
    align-items: center;
}
span.content_span {
    color: #ff0808;
}
.cnt_hdr_content_data p {
    font: normal normal bold 16px/20px Montserrat Alternates;
    letter-spacing: .30px;
}
.cnt_hdr_content_data_wrapdata {
    text-align: center;
}
.cnt_hdr_content_box_outers {
    display: flex;
    justify-content: center;
    align-items: center;
}
.cnt_audit_outer_wrap.plagiarisamdetail_otr_wrap .MuiDataGrid-row .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:first-child {
    padding-left: 8%;
}
.plagarism_analyzer_row{
    display: flex;
    gap: 16px;
    padding-top: 16px;
    padding-bottom: 5px;
    padding-left: 44px;
    margin-bottom: 10px;


}
.plagarism_analyzer_row .input_col{
    width: 50%;
    
}
.plagarism_analyzer_row .input_col input{
    width: 100%;
    height: 35px;
}

@media(max-width:500px){
    .cnt_hdr_content_data p {
        font: normal normal bold 9px / 15px Montserrat Alternates;
        letter-spacing: .30px;
        /* width: min-content; */
    }
    .plagiarisam_box_wrap{
        margin-left: 50px;
    }
    .plagiarisam_box_wrap .cnt_hdr_content_box_outers{
        display: block;
    }
    .box_background_wrap{
        justify-content: left;
    }
    /* .box_background_wrap h3{
        width:40%;
        font-size: 10px;
    } */
    .plagarism_analyzer_row .input_col{
        width: 80%;
        
    }
    .plagiarisam_scan_missing_waring_wrap{
       margin-bottom: 20px;
    }
}

@media(max-width:400px){
    .plagiarisam_box_wrap {
        margin-left: 20px;
    }
    .plagiarisam_scan_missing_waring_wrap{
        width: 140px;
    }
}


.plagarism_analyzer_row .button_col .rn_rpt_btn{

padding: 13px 15px ;
color: #F5F5F5;
background: #310a41 linear-gradient(8deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
border-radius: 5px;
opacity: 1;
backdrop-filter: blur(11px);
-webkit-backdrop-filter: blur(11px);
text-align: left;
letter-spacing: 0px;
opacity: 1;

}

::placeholder {
    text-align: center; 
 }
 
 /* or, for legacy browsers */
 
 ::-webkit-input-placeholder {
    text-align: center;
 }
 
 :-moz-placeholder { /* Firefox 18- */
    text-align: center;  
 }
 
 ::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
 }
 
 :-ms-input-placeholder {  
    text-align: center; 
 }
 .error_plagarism_report{
   padding: 0px 44px;
    color: #ff0000;;
 }

 .analyzbutton {
    background: #ff601f;
    color: #ffffff;
    border-radius: 5px;
    border: 1px solid #ff601f !important;
}

.analyzbutton:hover {
    /* background: #ffffff; */
    color: #000;
    /* border: 1px solid #ff601f !important; */
}

.MuiDataGrid-row .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:first-child,.MuiDataGrid-row .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:first-child font{
    /* display: block; */
    /* color: #fff; */
    max-height: none !important;
    overflow: unset;
    font-weight: 600;
    /* font-size: 13px; */
}


