.site_blw_wrap.regressiontest{
    margin-top: 35px;
}
/* td.table_td_regression {
    padding-left: 140px;
    color: white;
    font-size: 14px;
} */
.site_regress_chart_info{
    box-shadow: 1px -3px 20px #0b203394;
    width: 100%;
background: #fff;
opacity: 1;
backdrop-filter: blur(11px);
-webkit-backdrop-filter: blur(11px);
/* margin-top: 0px; */
padding-top: 40px !important;
padding-bottom: 30px !important;

}
.MuiTab-textColorPrimary.Mui-selected {
    z-index: 7;
    background: #ff601f !important;
    color: white !important;
}
.tabbutton{
    width: 130px;
height: 50px;
/* UI Properties */
background: transparent linear-gradient(142deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
border-radius: 9px;
opacity: 1;
backdrop-filter: blur(11px);
margin-left: 0px;
}
.competitor_test_filter .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    height: 80%;
}
.MuiDataGrid-row .css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child {
    position: unset !important;
}
.virtualScrollerContent .css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child {
    position: unset;
}
.css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child{
    position: unset !important;
}
.react-dropdown-select-clear {
    display: none !important;
}